<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import Spinner from "vue-simple-spinner";
import * as moment from "moment";

import Multiselect from 'vue-multiselect';
// import DatePicker from "vue2-datepicker";
// import FilterBtn from '../../../components/filter-btn.vue';
// import PaymentLinkForm from './payment-link-form.vue';
import PerPage from '../../../components/per-page.vue';
import SearchBy from '../../../components/search-by.vue';

import { paymentMethods, paymentLinks } from "@/state/helpers";
import {
  FETCH_PAYMENT_LINKS,
  DELETE_PAYMENT_LINKS,
  FETCH_MERCHANT_ENTITY_DETAILS,
  FETCH_MERCHANTS_LIST,
  FETCH_DETAILS_FOR_ALL_SUB_USERS,
  EXPORT_PAYMENT_LINK_LIST,
  FETCH_SOURCE_LIST,
} from "@/state/actions.type";
import Swal from "sweetalert2";
import DownloadBtn from '../../../components/download-btn.vue';
import Filters from '../../../components/filters.vue';

export default {
  components: {
    Layout,
    // PageHeader,
    Spinner,
    Multiselect,
    // FilterBtn,
    // DatePicker,
    // PaymentLinkForm,
    DownloadBtn,
    PerPage,
    SearchBy,
    Filters
  },
  data() {
    return {
      merchant_uuids:[],
      isAddPaymentLinkModalOpen: false,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      mor_filter: "",
      filter: null,
      createdByFilter: null,
      sortBy: "",
      sortDesc: true,
      fields: [
        { key: "created_at", sortable: true, label: "views.payment_links.table.fields.date", tooltip: "views.payment_links.table.tooltip.date" },
        { key: "link_ref", sortable: true, label: "views.payment_links.table.fields.payment_link_ref", tooltip: "views.payment_links.table.tooltip.payment_link_ref" },
        { key: "merchant_order_ref", sortable: true, label: "views.payment_links.table.fields.merchant_order_ref", tooltip: "views.payment_links.table.tooltip.merchant_order_ref" },
        { key: "amount", sortable: true, label: "views.payment_links.table.fields.amount", tooltip: "views.payment_links.table.tooltip.amount" },
        { key: "status", sortable: true, label: "views.payment_links.table.fields.status", tooltip: "views.payment_links.table.tooltip.status" },
        { key: "source", sortable: true, label: "Source" },
        { key: "buyerName", sortable: true, label: "views.payment_links.table.fields.customer_name", tooltip: "views.payment_links.table.tooltip.customer_name" },
        { key: "buyerPhone", sortable: true, label: "views.payment_links.table.fields.customer_phone", tooltip: "views.payment_links.table.tooltip.customer_phone" },
        { key: "buyerEmail", sortable: true, label: "views.payment_links.table.fields.customer_email", tooltip: "views.payment_links.table.tooltip.customer_email" },
        { key: "merchantEmail", sortable: true, label: "views.payment_links.table.fields.merchant_email", tooltip: "views.payment_links.table.tooltip.merchant_email", thClass: "",tdClass: "" },
        // { key: "delete_link", label: "button.delete" },
        { key: "show_details", sortable: false, label: '', stickyColumn: true },
      ],
      filterOptions: ['Merchant Link Ref', 'PortOne Link Ref', 'Customer Name', 'Customer Email', 'Customer Phone', 'Description' ],
      searchBy: {
        key: "Merchant Link Ref",
        value: ""
      },
      currentSelectedFilter: "",
      isFilterApplied: false,
      filters: {
        status:[],
        source: "",
      },
      from: "",
      to: "",
      // set_freqeuncy_period:[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      // sourceObj:{},
      sorting_data: {
        "sort_by_key": "",
        "order": ""
      },
    };
  },
  filters: {
    date(value) {
        return moment(value).format("DD MMM YYYY");
    },
    time(value) {
        return 'at ' + moment(value).format("hh:mm A");
    },
  },

  created() {
    this.$store.dispatch(`paymentlinks/${FETCH_SOURCE_LIST}`);
    this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`)
    this.fetchPaymentLinks();
    // this.isAddPaymentLinkModalOpen = this.$route.query.new === "payment-link";
    if(this.isMasterMerchant){
      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
        pageIndex: 1,
        pagesize: 10,
        filters: { source: "default" },
      });
      // this.fields[10].thClass = ""
      // this.fields[10].tdClass = ""
    }else{
      // this.fields[10].thClass = "d-none"
      // this.fields[10].tdClass = "d-none"
    }
    if(this.isGlobalUser && !this.isMasterMerchant) {
      this.fetchSubUsersList();
    }
  },

  computed: {
    paymentLinksDataFromStore() {
      this.$store.state.paymentlinks.content.forEach(element => {
        if(element.status === 'Created') {
          element.delete_link = element.link_ref
        }
      });
      return this.$store.state.paymentlinks.content || [];
    },

    isFetchingTransactions() {
      return this.$store.state.paymentlinks.isFetchingData;
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },

    sourceList() {
      return this.$store.state.paymentlinks.sourceList;
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    rows() {
      return this.$store.state.paymentlinks.total_elements;
    },

    convertCountryToCurrency() {
      return this.$store.getters["auth/getConvertCountry"];
    },

    isGlobalUser() {
      return this.$store.state.auth.currentUser.is_global_user;
    },

    subUserList() {
      return this.$store.state.accounts.subUserData || [];
    },

    // items() {
    //   return [
    //     {
    //       text: "PortOne",
    //       active: true,
    //     },
    //     {
    //       text: this.$t('views.payment_links.title'),
    //       active: false,
    //     },
    //   ];
    // },

    // title() {
    //   return this.$t('views.payment_links.title');
    // },

    lastCurrency() {
      return this.paymentLinksDataFromStore.length > 0 ? this.paymentLinksDataFromStore[0].currency : "";
    },

    lastCountry() {
      return this.paymentLinksDataFromStore.length > 0 ? this.paymentLinksDataFromStore[0].countryCode : "";
    },
  },

  watch: {
    // sourceObj(value){
    //   if(value){
    //     this.filters.source = value.key
    //   } else {
    //     this.filters.source = "";
    //   }
    // },
    mor_filter(value) {
      if(!value) {
        this.fetchPaymentLinks();
      }
    },
    // 'from': function(newFromDate) {
    //   if (newFromDate !== null && newFromDate !== "2006-01-02T00:00:00.000Z" && newFromDate !== "") {
    //     const toDate = new Date(newFromDate);
    //     toDate.setHours(23, 59); // Set time to the end of the day
    //     this.to = toDate;
    //   }
    //   else{
    //     this.to = "";
    //   }
    // }
  },

  methods: {
    ...paymentMethods,
    ...paymentLinks,

    fetchPaymentLinks() {
      let filterTosend = this.getFiltersDetails();
      this.$store.dispatch(`paymentlinks/${FETCH_PAYMENT_LINKS}`, {
        pageIndex: this.currentPage,
        pagesize: this.perPage,
        filters: filterTosend,
        isMasterMerchant:this.isMasterMerchant,
        merchant_uuids:this.merchant_uuids
      }).then(() => {
        if(this.filterApplied()) {
          this.isFilterApplied = true;
        } else {
          this.isFilterApplied = false;
        }
      });
    },

    onCopySuccess() {
      this.$notify({
        type: "success",
        text: this.$t('views.payment_links.copy_success'),
        closeOnClick: true,
      });
    },
    
    // changefreqeuncy(value) {
    //   var array = [];
    //   if(value == 'Daily'){
    //     array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
    //   }else if(value == 'Weekly'){
    //     array = [1, 2, 3, 4]
    //   }else{
    //     array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    //   }
    //   this.set_freqeuncy_period = array;
    // },

    /**
     * Search the table data with search input
     */

    closePaymentLinkModal() {
      // this.isAddPaymentLinkModalOpen = false;
      this.fetchPaymentLinks();
    },

    sortByDate(a, b, key) {
      if (key == "date") {
        const d1 = new Date(a[key]).getTime();
        const d2 = new Date(b[key]).getTime();
        return d1 - d2;
      }
    },

    getFiltersDetails(){
      this.filters.from = "";
      this.filters.to = "";

      // if(this.fromDate) {
      //   payload.from = moment(this.fromDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
      // } else {
      //   payload.from = moment('2018-01-01T00:00:00.000').format('YYYY-MM-DD HH:mm:ss');
      // }

      // if(this.toDate) {
      //   payload.to = moment(this.toDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
      // } else {
      //   payload.to = moment().format('YYYY-MM-DD HH:mm:ss')
      // }


      if(this.from) {
        this.filters.from = moment(this.from, 'DD-MM-YYYY HH:mm').format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        this.filters.from = moment('2018-01-01T00:00:00.000').format("YYYY-MM-DDTHH:mm:ssZ");
      }

      if(this.to) {
        this.filters.to = moment(this.to, 'DD-MM-YYYY HH:mm').format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        this.filters.to = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      }



      // if(this.from) {
      //   this.filters.from = moment(new Date(this.from.getFullYear(), this.from.getMonth(), this.from.getDate(), 0, 0, 0 )).format("YYYY-MM-DDTHH:mm:ssZ");
      // }
      // if(this.to) {
      //   this.filters.to = moment(new Date(this.to.getFullYear(), this.to.getMonth(), this.to.getDate(), 23, 59, 59 )).format("YYYY-MM-DDTHH:mm:ssZ");
      // }
      let extrafilters = {};
      if(this.searchBy.value !== ""){
        if(this.searchBy.key == "PortOne Link Ref") {
          extrafilters['payment_link_ref'] = this.searchBy.value.trim() 
        }else if(this.searchBy.key == "Customer Email") {
          extrafilters['customer_email_address'] = this.searchBy.value.trim()
        }else if(this.searchBy.key == "Customer Phone") {
          extrafilters['customer_phone_number'] = this.searchBy.value.trim()
        }
        else if(this.searchBy.key == "Customer Name") {
          extrafilters['customer_name'] = this.searchBy.value.trim()
        } 
        else if(this.searchBy.key == "Description") {
          extrafilters['description'] = this.searchBy.value.trim()
        } 
        else if(this.searchBy.key == "Merchant Link Ref"){
          extrafilters['merchant_order_ref'] = this.searchBy.value.trim()
        }
      }
      if(this.isGlobalUser && this.createdByFilter) {
        this.filters.created_by = this.createdByFilter.uuid;
      } else {
        delete this.filters.created_by;
      }
      let filtersTosend = {...this.filters,...extrafilters};
      return filtersTosend
    },

    resetAdvanceFilters() {
      if(this.isFilterApplied) {
        this.filters = {
          from: "",
          to: "",
          status:[],
          source:"",
        };
        this.createdByFilter = null;
        this.from = "";
        this.to = "";
        // this.sourceObj = []
        this.fetchPaymentLinks();
        this.isFilterApplied = false;
      }
    },

    filterApplied() {
      return Boolean((this.filters.from && this.filters.to) || (this.filters.status && this.filters.status.length)) || Boolean(this.createdByFilter);
    },

    deletePaymentLink(linkRef) {
      Swal.fire({
        title: this.$t('views.payment_links.delete_confirmation'),
        text:"Link Ref - "+ linkRef,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('button.cancel'),
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch(`paymentlinks/${DELETE_PAYMENT_LINKS}`, linkRef)
            .then((response) => {
              console.log(response);
              Swal.fire(this.$t('common.deleted'), this.$t('views.payment_links.delete_success'), "success");
              setTimeout(() => {
                this.fetchPaymentLinks();
              }, 500);
            })
            .catch((error) => {
              // error
              console.log("error", error);
              Swal.fire(this.$t('views.payment_links.failed'), this.$t('views.payment_links.delete_failed'), "error");
            });
        }
      });
    },

    openPaymentLinkDetailsPage(pageRef, submerchant_key) {
      this.$router.push({path: '/payment-links-details', query: { pageRef: pageRef, key: this.isMasterMerchant ? submerchant_key : '' }});
    },

    disabledAfterToday(date) {
      return date > new Date();
    },
    disabledAfterEndDate(date) {
      if(this.to) {
        return date > new Date(this.to) || date > new Date();
      }
      return date > new Date();
    },
    // disabledBeforeStartDate(date) {
    //   if(this.from) {
    //     return date < new Date(this.from) || date > new Date();
    //   }
    //   return date > new Date();
    // },

    fetchSubUsersList() {
      this.$store.dispatch(`accounts/${FETCH_DETAILS_FOR_ALL_SUB_USERS}`);
    },

    downloadExcel() {
      let filterTosend = this.getFiltersDetails();
      this.$store.dispatch(`paymentlinks/${EXPORT_PAYMENT_LINK_LIST}`, {
        pageIndex: this.currentPage,
        pagesize: this.perPage,
        filters: filterTosend,
        isMasterMerchant:this.isMasterMerchant,
        merchant_uuids:this.merchant_uuids,
        from: this.filters.from,
        to: this.filters.to
      });
    },
    dateChanged(value) {
      this.from = value[0];
      this.to = value[1];

      this.fetchPaymentLinks();
    },
    toggleFilter() {
      this.$root.$emit('bv::toggle::collapse', 'transaction-filter-collapse');
    },
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  }
};
</script>

<template>
  <Layout>
    <!-- <PageHeader /> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="pt-0">
            <div class="row d-flex justify-content-end align-items-start">
              <router-link
                  :to="'/payment-links/create-payment-links?lastCurrency=' + lastCurrency +'&lastCountry='+lastCountry"
                >
                <button
                  v-if="!isMasterMerchant"
                  class="btn btn-primary btn-sm mr-3"
                  style="padding: 12px 16px;font-weight: 400;font-size: 14px;line-height: 20px;"
                >
                <span style="padding-right: 10px;">+</span>{{ $t("views.payment_links.create_pay_link") }}
                </button>
                </router-link>
              <div class="col-sm-12 col-md-3" v-if="isMasterMerchant">
                <multiselect
                  v-model="merchant_uuids"
                  :options="merchantListDataFromStore"
                  @input="fetchPaymentLinks"
                  placeholder="Search Merchants"
                  track-by="email_id"
                  label="email_id"
                  :searchable="true"
                  :multiple="true"
                ></multiselect>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-xl-12">
                <!-- <div @click="resetAdvanceFilters">
                    <FilterBtn :isActive="isFilterApplied" v-b-toggle.transaction-filter-collapse />
                  </div> -->
                <filters class="mb-3" @date-change="dateChanged" @toggle-filter="toggleFilter" />
                <!-- <div id="tickets-table_length" class="dataTables_length ml-3">
                  <label class="d-inline-flex align-items-center">
                    <span class="text-nowrap">{{ $t('common.show') }}</span>&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;<span class="text-nowrap">{{ $t('common.entries') }}</span>
                  </label>
                </div> -->
              </div>

              <!-- Custom Search -->
              <!-- <div class="col-sm-12 col-xl-8 d-flex justify-content-end align-items-center">
                <div class="d-flex">
                  <label class="mr-2 text-nowrap" style="width: 100px;">{{ $t('common.search_by') }}</label>
                  <b-form-select class="w-50" v-model="currentSelectedFilter" size="sm" :options="filterOptions"></b-form-select>&nbsp;
                  <b-input-group class="w-75">
                    <b-form-input type="search" v-model="mor_filter" @keyup="fetchPaymentLinks" @keyup.enter="fetchPaymentLinks" class="form-control form-control-sm ml-2" :placeholder="$t('common.search_placeholder')"></b-form-input>
                    <b-input-group-append>
                      <span>
                        <button class="btn btn-primary btn-block btn-sm" @click="fetchPaymentLinks">{{ $t('button.search') }}</button>
                      </span>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div> -->

              <!-- Custom Search End-->
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-12">
                <b-collapse id="transaction-filter-collapse" @hidden="resetAdvanceFilters">
                  <b-card style="box-shadow: none;border-radius: 15px;border: 1px #DCDAD5 solid !important ;margin-bottom: 15px;" class="border border-light">
                    <!-- <div class="row">
                      <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                        <label for="startdate" style="display: block;">{{ "Start Date" }}</label>
                        <date-picker
                          type="date"
                          placeholder="Start Date"
                          format="DD-MM-YYYY"
                          v-model="from"
                          name="startdate"
                          class="custom-datepicker"
                          prefix-class="xmx"
                          :disabled-date="disabledAfterEndDate"
                        ></date-picker>
                      </div>
                      <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                        <label for="enddate" style="display: block;">{{ "End Date" }}</label>
                        <date-picker
                          type="date"
                          placeholder="End Date"
                          v-model="to"
                          format="DD-MM-YYYY"
                          name="enddate"
                          class="custom-datepicker"
                          prefix-class="xmx"
                          :disabled-date="disabledBeforeStartDate"
                        ></date-picker>
                      </div>
                    </div> -->
                    <div class="row mt-3">
                      <div class="col-sm-12 col-md-6 col-xl-4" v-if="isGlobalUser && !isMasterMerchant">
                        <label for="enddate" style="display: block;">{{ "Created By" }}</label>
                        <multiselect
                          v-model="createdByFilter"
                          placeholder="Search User"
                          :searchable="true"
                          :options="subUserList"
                          track-by="uuid"
                          label="email_address"
                          :multiple="false"
                        ></multiselect>
                      </div>
                      <div class="col-sm-12 col-md-6 col-xl-4">
                        <label for="enddate" style="display: block;">{{ "Status" }}</label>
                        <multiselect
                          v-model="filters.status"
                          :placeholder="$t('common.search_status')"
                          :searchable="true"
                          :options="['Success', 'Cancelled', 'Created', 'Processing', 'Expired', 'Under Review', 'Refunded', 'Partially Refunded']"
                          :multiple="true"
                        ></multiselect>
                      </div>
                      <div class="col-sm-12 col-md-6 col-xl-4">
                        <label for="enddate" style="display: block;">{{ "Source" }}</label>
                        <multiselect
                          v-model="filters.source"
                          placeholder="Search Source"
                          :searchable="true"
                          :options="sourceList"
                          :multiple="false"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                      <b-btn variant="link" @click="resetAdvanceFilters" class="text-chai font-16px">Clear Filters</b-btn>
                      <div>
                        <b-btn variant="primary" @click="toggleFilter"  class="mr-2 cancel bg-white text-dark">Cancel</b-btn>
                        <b-btn variant="primary" class="btn-padding" @click="fetchPaymentLinks">Apply</b-btn>
                      </div>
                    </div>
                    <!-- <div class="row ">
                      <div class="col-sm-12 col-md-6 col-xl-3 ml-auto">
                        <div class="row">
                          <div class="col-sm-12 col-md-6">
                            <b-button variant="primary" style="height: 55px;" class="btn btn-block btn-sm" @click="fetchPaymentLinks" >{{ $t('button.apply') }}</b-button>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <b-button variant="light" style="height: 55px;" class="btn btn-block btn-sm" @click="resetAdvanceFilters" >{{ $t('button.clear') }}</b-button>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </b-card>
                </b-collapse>
              </div>
            </div>

              <div class="d-flex justify-content-between mb-3 mt-3">
                <search-by v-model="searchBy" :options="filterOptions" @search-by-clicked="fetchPaymentLinks" />
                <download-btn @download-button-clicked="downloadExcel()" />
              </div>

              <b-table
                class="table-custom text-left table-centered"
                :items="paymentLinksDataFromStore"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-compare="sortByDate"
                sticky-header="50vh"
                show-empty
                :empty-text="$t('common.no-records')"
                busy.sync="true"
                hover
              >
                <template v-slot:head()="data">
                  <span v-b-tooltip.hover.top :title='$t(data.field.tooltip)'>
                    {{ $t(data.label) }}
                    <img v-show="data.field.sortable && sortBy !== data.column" class="ml-2" src="@/assets/images/arrow-3.svg" alt="sort_up">
                    <img v-show="data.field.sortable && sortBy == data.column && !sortDesc" class="ml-2" src="@/assets/images/sort_asc.svg" alt="sort_asc">
                    <img v-show="data.field.sortable && sortBy == data.column && sortDesc" class="ml-2" src="@/assets/images/sort_desc.svg" alt="sort_desc">
                  </span>
                </template>

                <template #cell(created_at)="row">
                  
                    <p class="table-date">{{ row.value | date }}</p>
                    <p class="table-time">{{ row.value | time }}</p>
                </template>

                <template v-slot:cell(status)="row">
                  <div
                    class="badge font-size-12"
                    style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                    :class="{
                      'badge-soft-danger':
                        `${row.value}` === 'Failed' ||
                        `${row.value}` === 'Expired'||
                        `${row.value}` === 'Cancelled',
                      'badge-soft-success': `${row.value}` === 'Success',
                      'badge-soft-warning': `${row.value}` === 'Created'||
                        `${row.value}` === 'Processing',
                      'badge-orange': row.value === 'Under Review',
                      'refunded': row.value === 'Refunded',
                      'partial-refund': row.value === 'Partially Refunded'
                    }"
                  >
                    {{ row.value }}
                  </div>
                </template>
                <template v-slot:cell(link_ref)="{ value, item }">
                  <span v-show="!value">{{ $t("common.na") }}</span>
                  <div v-show="value" class="d-flex justify-content-center align-items-center">
                    <a class="d-flex" target="_black" v-b-tooltip.hover.right :title="value" :href="item.link">
                      <p class="text-truncate mb-0" style="width: 200px; color:#FC6B2D; font-weight: 500;">{{ value }}</p>
                      <!-- <i style="font-size: 15px;" class="mdi mdi-open-in-new"></i> -->
                      <svg xmlns="http://www.w3.org/2000/svg" style="padding-left: 3px;" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <g opacity="0.7" clip-path="url(#clip0_1540_47447)">
                        <path d="M9.75 8.25L15.9 2.1M16.5 5.1V1.5H12.9M8.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V9.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1540_47447">
                        <rect width="18" height="18" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                    </a>
                    <span class="ml-3" 
                        v-show="item.link"
                        v-clipboard:copy="item.link"
                        v-clipboard:success="onCopySuccess"
                        v-b-tooltip.hover.right
                        :title="$t('common.copy')"
                        style="cursor: pointer; width: 40px;height: 40px;background: rgba(245, 244, 243, 1);border-radius: 8px;position: relative;">
                      <svg style="position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <g opacity="0.7" clip-path="url(#clip0_1540_47398)">
                          <path d="M12 9.675V12.825C12 15.45 10.95 16.5 8.325 16.5H5.175C2.55 16.5 1.5 15.45 1.5 12.825V9.675C1.5 7.05 2.55 6 5.175 6H8.325C10.95 6 12 7.05 12 9.675Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M16.5 5.175V8.325C16.5 10.95 15.45 12 12.825 12H12V9.675C12 7.05 10.95 6 8.325 6H6V5.175C6 2.55 7.05 1.5 9.675 1.5H12.825C15.45 1.5 16.5 2.55 16.5 5.175Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_1540_47398">
                            <rect width="18" height="18" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </template>
                <!-- <template v-slot:cell(delete_link)="row">
                  <span  class="btn btn-sm btn-danger" @click="deletePaymentLink(row.value)">{{ $t("button.delete") }}</span>
                </template> -->
                <template #cell(show_details)="row">

                      <div style="display: flex;align-items: center;gap: 10px; cursor: pointer;">
                        <b-dropdown class="show_details" size="sm" variant="white" dropleft>
                                <template v-slot:button-content>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                  <path d="M7.5 14.25C7.5 15.075 8.175 15.75 9 15.75C9.825 15.75 10.5 15.075 10.5 14.25C10.5 13.425 9.825 12.75 9 12.75C8.175 12.75 7.5 13.425 7.5 14.25ZM7.5 3.75C7.5 4.575 8.175 5.25 9 5.25C9.825 5.25 10.5 4.575 10.5 3.75C10.5 2.925 9.825 2.25 9 2.25C8.175 2.25 7.5 2.925 7.5 3.75ZM7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5C8.175 7.5 7.5 8.175 7.5 9Z" stroke="black" stroke-opacity="0.3"/>
                                </svg>
                                </template>
                              <b-dropdown-item-button @click="openPaymentLinkDetailsPage(row.item.link_ref, row.item.chaipaykey)">{{ $t('views.payment_links.payment_link_details') }}</b-dropdown-item-button>
                              <b-dropdown-item-button v-if="row.item.delete_link" @click="deletePaymentLink(row.item.delete_link)">{{ $t("button.delete") }}</b-dropdown-item-button>
                        </b-dropdown>
                    </div>


                      <!-- <b-dropdown size="sm p-0" variant="white" dropleft>
                        <template v-slot:button-content>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path d="M7.5 14.25C7.5 15.075 8.175 15.75 9 15.75C9.825 15.75 10.5 15.075 10.5 14.25C10.5 13.425 9.825 12.75 9 12.75C8.175 12.75 7.5 13.425 7.5 14.25ZM7.5 3.75C7.5 4.575 8.175 5.25 9 5.25C9.825 5.25 10.5 4.575 10.5 3.75C10.5 2.925 9.825 2.25 9 2.25C8.175 2.25 7.5 2.925 7.5 3.75ZM7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5C8.175 7.5 7.5 8.175 7.5 9Z" stroke="black" stroke-opacity="0.3"/>
                        </svg>
                        </template>
                      </b-dropdown> -->
                    </template>
              </b-table>
              <Spinner v-if="isFetchingTransactions === true"></Spinner>
                 <div class="d-flex justify-content-between mb-3">
                    <!-- pagination -->
                    <per-page
                      v-model="perPage"
                      :currentPage="currentPage"
                      :totalRows="rows"
                      :tableRowsCount="paymentLinksDataFromStore.length"
                      @input="fetchPaymentLinks"
                    />
                    <b-pagination v-model="currentPage" :current-page="currentPage" :total-rows="rows" :per-page="perPage" @input="fetchPaymentLinks" ></b-pagination>
                </div>
            <!-- </b-tab> -->
            <!-- </b-tabs> -->
          </div>
          <!-- <div> -->
          <!-- Right Sidebar -->
            <!-- <div class="right-bar"
              :class="{
                'active': isAddPaymentLinkModalOpen
              }"> -->
              <!-- <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt /> -->
              <!-- <div data-simplebar class="h-100" style="overflow-x: auto;"> -->
                <!-- <div class="rightbar-title">
                  <div class="right-top-section"> -->
                    <!-- <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt /> -->
                    <!-- <div class="card-body-custom">
                      <i class="mdi mdi-close noti-icon float-right cursor-pointer" style="position: relative;z-index: 1;right: 15px;top: 15px;font-size: 20px;" @click="closePaymentLinkModal"></i>
                      <div class="media-body media-body-custom">
                        <p class="d-inline-block pl-4" style="font-size: 20px;margin-top: 30px;margin-bottom: 30px;">
                          {{$t('views.payment_links.create_pay_link')}}
                        </p>
                      </div>
                    </div> -->
                  <!-- </div>
                </div> -->
                <!-- <div class="px-4"> -->
                  <!-- <form id="modal" action="#" @submit.prevent="createRecon">
                    <div class="row">
                      <div class="col-lg-12">
                      </div>
                    </div>
                    <div class="row pb-3">

                    </div>
                    <div class="text-left" style="margin-top: 60px;">
                      <button
                        type="submit"
                        class="btn btn-primary dark-bg ml-1 col-4"
                      >
                        Submit
                      </button>
                      <b-button class="ml-2 col-4" variant="light" @click="closePaymentLinkModal"
                        >Cancel</b-button>
                    </div>
                  </form> -->
                  <!-- <PaymentLinkForm v-if="isAddPaymentLinkModalOpen" /> -->
                <!-- </div> -->
              <!-- </div> -->
              <!-- end slimscroll-menu-->
            <!-- </div> -->
              <!-- Right bar overlay-->
            <!-- <div @click="closePaymentLinkModal" class="rightbar-overlay"
              :class="{
                'active': isAddPaymentLinkModalOpen
            }"></div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>

    <!-- <b-modal
      size="md"
      id="create-payment-links-modal"
      class="trxn-details-modal"
      v-model="isAddPaymentLinkModalOpen"
      :title="$t('views.payment_links.create_pay_link')"
      title-class="text-dark font-18"
      @hide="closePaymentLinkModal"
      no-close-on-backdrop
      hide-footer
    >
      <div class="row">
        <div class="m-3">
          <div class="">
            <form
              v-show="!orderLink"
              class="row"
              @submit.prevent="submitPaymentLinks"
            >
              <div class="col-lg-7 col-7">
                <div class="form-group">
                  <label
                    ><i class="mdi mdi-cash mr-1"></i
                    >{{ $t("views.payment_links.amount")
                    }}<span class="text text-danger">*</span></label
                  >
                  <b-form-input
                    v-model="$v.chaiPayMerchantDetails.amount.$model"
                    type="number"
                    step="any"
                    class="form-control"
                    :placeholder="$t('views.payment_links.enter_payment_amount')"
                    name="amount"
                    :state='validateState("amount")'
                    :class="{
                      'is-invalid':
                        typesubmit && $v.chaiPayMerchantDetails.amount.$error,
                    }"
                  />
                  <div
                    v-if="$v.chaiPayMerchantDetails.amount.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.chaiPayMerchantDetails.amount.required">{{
                      $t('common.required')
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-lg-5 col-5">
                <div class="form-group position-relative">
                  <label
                    >{{ $t("views.payment_links.currency")
                    }}<span class="text text-danger">*</span></label
                  >
                  <img v-if="chaiPayMerchantDetails.currency == 'VND'" class="flag_images" src="@/assets/images/flags/vietnam1.png" alt="">
                  <img v-if="chaiPayMerchantDetails.currency == 'THB'" class="flag_images" src="@/assets/images/flags/thailand.jpeg" alt="">
                  <img v-if="chaiPayMerchantDetails.currency == 'SGD'" class="flag_images" src="@/assets/images/flags/singapore.png" alt="">
                  <img v-if="chaiPayMerchantDetails.currency == 'IDR'" class="flag_images" src="@/assets/images/flags/indonesia.png" alt="">
                  <img v-if="chaiPayMerchantDetails.currency == 'PHP'" class="flag_images" src="@/assets/images/flags/philippines.png" alt="">
                  <img v-if="chaiPayMerchantDetails.currency == 'TWD'" class="flag_images" src="@/assets/images/flags/taiwan.png" alt="">
                  <img v-if="chaiPayMerchantDetails.currency == 'MYR'" class="flag_images" src="@/assets/images/flags/malaysia.svg" alt="">
                  <b-form-select
                    name="currency"
                    v-model="chaiPayMerchantDetails.currency"
                    class="form-control custom-select-for-flag"
                  >
                    <b-form-select-option value="IDR">{{ "IDR" }}</b-form-select-option>
                    <b-form-select-option value="PHP">{{ "PHP" }}</b-form-select-option>
                    <b-form-select-option value="SGD">{{ "SGD" }}</b-form-select-option>
                    <b-form-select-option value="THB">{{ "THB" }}</b-form-select-option>
                    <b-form-select-option value="VND">{{ "VND" }}</b-form-select-option>
                    <b-form-select-option value="TWD">{{ "TWD" }}</b-form-select-option>
                    <b-form-select-option value="MYR">{{ "MYR" }}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label
                    ><i class="mdi mdi-tooltip-edit mr-2"></i
                    >{{ $t("views.payment_links.description") }}</label
                  >
                  <div class="input-group">
                  <input
                    v-model="chaiPayMerchantDetails.description"
                    type="text"
                    class="form-control"
                    :placeholder="$t('views.payment_links.enter_description')"
                    name="description"
                    :maxlength="max"
                  />
                  <div class="input-group-addon" v-text="(max - chaiPayMerchantDetails.description.length)"></div>
                  </div> -->
                  <!-- ignore -->
                  <!-- <div
                    v-if="typesubmit && $v.chaiPayMerchantDetails.billing_details_billing_email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.chaiPayMerchantDetails.billing_details_billing_email.email">{{ 'This is not valid email.' }}</span>
                  </div> -->
                <!-- </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label
                    ><i class="mdi mdi-account mr-2"></i
                    >{{ $t("views.payment_links.customer_name") }}</label
                  >
                  <input
                    v-model="
                      chaiPayMerchantDetails.billing_details_billing_name
                    "
                    type="text"
                    class="form-control"
                    :placeholder="$t('views.payment_links.enter_customer_name')"
                    name="billing_details_billing_name"
                  />
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label
                    ><i class="mdi mdi-phone mr-2"></i
                    >{{ $t("views.payment_links.customer_phone") }}</label
                  >
                  <VuePhoneNumberInput
                    v-model="phoneNumber"
                    id='VuePhoneNumber'
                    @update="phoneNumberVal = $event"
                    :default-country-code="this.$store.state.auth.currentUser.country"
                    :only-countries="['IN', 'ID', 'MY', 'PH', 'SG', 'KR', 'TH', 'VN']"
                  /> -->
                  <!-- ignore -->
                  <!-- <div
                    v-if="typesubmit && $v.chaiPayMerchantDetails.billing_details_billing_phone.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.chaiPayMerchantDetails.billing_details_billing_phone.required">{{ 'This value is required.' }}</span>
                  </div> -->
                <!-- </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label
                    ><i class="mdi mdi-email mr-2"></i
                    >{{ $t("views.payment_links.customer_email") }}</label
                  >
                  <b-form-input
                    v-model="$v.chaiPayMerchantDetails.billing_details_billing_email.$model"
                    type="email"
                    class="form-control billing_details_billing_email"
                    :placeholder="$t('views.payment_links.enter_customer_email')"
                    name="billing_details_billing_email"
                    :state='validateState("billing_details_billing_email")'
                  />
                  <div
                    v-if="$v.chaiPayMerchantDetails.billing_details_billing_email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.chaiPayMerchantDetails.billing_details_billing_email.email">{{ 'This is not valid email.' }}</span>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label>{{ $t('common.required') }}<span class="text text-danger">*</span></label>
                  <input
                    v-model="chaiPayMerchantDetails.merchant_order_id"
                    type="text"
                    class="form-control"
                    :placeholder="$t('views.payment_links.enter_merchant_order_reference')"
                    name="merchant_order_id"
                    :class="{
                      'is-invalid':
                        typesubmit &&
                        $v.chaiPayMerchantDetails.merchant_order_id.$error,
                    }"
                  />
                  <div
                    v-if="typesubmit && $v.chaiPayMerchantDetails.merchant_order_id.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.chaiPayMerchantDetails.merchant_order_id.required"
                      >{{ $t("common.required") }}</span
                    >
                  </div>
                </div>
              </div>
               <div class="col-lg-12">
                <div class="form-group">
                  <label>{{ $t("common.custom_url") }}</label>
                  <div>
                  </div>
                  <div class="input-group">
                    <span style="line-height: 38px;">{{checkout_base_url}}/?ref=</span>
                      <input
                        v-model="chaiPayMerchantDetails.ref_link"
                        type="text"
                        class="form-control"
                        :placeholder="$t('views.payment_links.reference')"
                        name="merchant_ref_link"
                        @keyup="checkRefLinkKeyup"
                      />
                      <img v-if="chaiPayMerchantDetails.checked_ref_link" style="position: absolute;width: 20px;right: 70px;top: 10px;z-index: 99;" src="@/assets/images/success-tick.svg" alt="">
                      <div class="input-group-addon" @click="checkRefLink" style="background-color: #252B3A;color: #fff; cursor:pointer;">{{ $t('views.payment_links.verify') }}</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-6">
                <div class="form-group">
                  <label
                    >{{ $t("views.payment_links.expiry_hours")
                    }}<span class="text text-danger">*</span></label
                  >
                  <input
                    v-model="chaiPayMerchantDetails.expiry_hours"
                    type="text"
                    class="form-control"
                    :placeholder="$t('views.payment_links.enter_expiry_hours')"
                    min="1"
                    name="expiry_hours"
                    :class="{
                      'is-invalid':
                        typesubmit &&
                        $v.chaiPayMerchantDetails.expiry_hours.$error,
                    }"
                  />
                  <div
                    v-if="
                      typesubmit &&
                      $v.chaiPayMerchantDetails.expiry_hours.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.chaiPayMerchantDetails.expiry_hours.required"
                      >{{ $t("common.required") }}</span
                    >
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-6">
                <div class="form-group position-relative">
                  <label
                    >{{ $t("views.payment_links.country")
                    }}<span class="text text-danger">*</span></label
                  >
                  <img v-if="chaiPayMerchantDetails.country == 'VN'" class="flag_images" src="@/assets/images/flags/vietnam1.png" alt="">
                  <img v-if="chaiPayMerchantDetails.country == 'TH'" class="flag_images" src="@/assets/images/flags/thailand.jpeg" alt="">
                  <img v-if="chaiPayMerchantDetails.country == 'SG'" class="flag_images" src="@/assets/images/flags/singapore.png" alt="">
                  <img v-if="chaiPayMerchantDetails.country == 'ID'" class="flag_images" src="@/assets/images/flags/indonesia.png" alt="">
                  <img v-if="chaiPayMerchantDetails.country == 'PH'" class="flag_images" src="@/assets/images/flags/philippines.png" alt="">
                  <img v-if="chaiPayMerchantDetails.country == 'TW'" class="flag_images" src="@/assets/images/flags/taiwan.png" alt="">
                  <img v-if="chaiPayMerchantDetails.country == 'MY'" class="flag_images" src="@/assets/images/flags/malaysia.svg" alt="">
                  <b-form-select
                    name="country"
                    v-model="chaiPayMerchantDetails.country"
                    class="form-control custom-select-for-flag"
                  >
                    <b-form-select-option value="ID">{{
                      "Indonesia"
                    }}</b-form-select-option>
                    <b-form-select-option value="PH">{{
                      "Philippines"
                    }}</b-form-select-option>
                    <b-form-select-option value="SG">{{
                      "Singapore"
                    }}</b-form-select-option>
                    <b-form-select-option value="TH">{{
                      "Thailand"
                    }}</b-form-select-option>
                    <b-form-select-option value="VN">{{
                      "Vietnam"
                    }}</b-form-select-option>
                    <b-form-select-option value="TW">{{
                      "Taiwan"
                    }}</b-form-select-option>
                    <b-form-select-option value="MY">{{
                      "Malaysia"
                    }}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>

              <div class="col-lg-12 my-1">
                <button type="submit" class="btn btn-primary mr-2">
                  {{ $t("button.save") }}
                </button>
                <button @click.prevent="(event) => (isAddPaymentLinkModalOpen = false)" class="btn btn-light">
                  {{ $t("button.close") }}
                </button>
              </div>
            </form>

            <div v-show="orderLink" class="col-lg-12 my-1 text-center">
              <img src="@/assets/images/success.png" alt height="100" />
              <p class="text text-success">
                {{ $t("views.payment_links.create_pay_link_success") }}
              </p>
              <a target="_blank" :href="orderLink">{{ orderLink }}</a>
              <i
                v-clipboard:copy="orderLink"
                v-clipboard:success="onCopySuccess"
                class="mdi mdi-content-copy btn-lg btn"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </b-modal> -->
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}

::v-deep .custom-datepicker .xmx-input{
  height: 35px !important;
  padding-top: 4px;
  min-height: unset;
}

::v-deep .input-group-addon{
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1;
  text-align: center;
  background-color: #ecf0f1;
  border: 1px solid #dce4ec;
  border-left-color: transparent;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.right-top-section{
  /* border-bottom: 1px solid #D9D9D9; */
  /* background-color: #F7F7F7;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%; */
  position: relative;
  z-index: 1;
}
.right-bar{
  /* width: 600px;
  right: -600px; */
  width: 1300px;
  right: -1300px;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}

.cursor-pointer{
  cursor: pointer;
}
@media (max-width: 700px) {
  .right-bar{
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
.badge-orange {
  color: #FA8234;
  background-color: #FA823414;
}
::v-deep  .show_details ul{
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
    border-radius: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    transform: translate3d(-232px, 0px, 0px) !important;
    width: 220px;

}
::v-deep   .show_details ul button{
    padding: 12px 25px;
    border-radius: 0px;
}
.refunded{
    background: rgba(222, 53, 53, 0.10); 
    color: #DE3535;
}
.partial-refund{
    background: rgba(227, 179, 7, 0.10);
    color: #C59900;
}
.badge-soft-success{
    background: rgba(33, 150, 83, 0.10);
    color: #219653;
}
.badge-soft-danger{
    background: rgba(222, 53, 53, 0.10);
    color: #DE3535;
}
</style>